<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <CategoryTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="entity.title"
                                    outlined
                                    label="Заголовок"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="entity.code"
                                    outlined
                                    label="Код"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="entity.parent_id"
                                    label="Родительская категория"
                                    :items="categories"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col xs="12" sm="6" md="4" lg="3" class="d-flex flex-column justify-space-between">
                                <span class="mb-4">
                                    <v-img v-if="entity.picture" :src="imageSrc(entity.picture)" />
                                </span>
                                <span>
                                    <v-file-input
                                        label="Основная картинка"
                                        outlined
                                        prepend-icon="mdi-image"
                                        hide-details
                                        @change="setMainPicture"
                                    />
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="entity.description"
                                    outlined
                                    label="Описание"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-switch
                                    v-model="entity.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="entity.position"
                                    outlined
                                    label="Сортировка"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin && $route.params.id"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>


        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить пользователя? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import CategoryTabLinks from '@/components/categories/CategoryTabLinks';

    import { imageSrc, priceFormated, translit } from '../../helpers';

    export default {
        name: 'CategoryEdit',

        components: {
            CategoryTabLinks,
        },

        data() {
            return {
                imageSrc,
                priceFormated,
                entity: {
                    code: '',
                    title: '',
                    picture: '',
                    description: '',
                    position: 0,
                    enabled: true,
                },
                deleteModal: false,
                formatedPrice: 0
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('categories', {
                oldEntity: state => state.entity,
                categories: state => state.entities
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const id = this.$route.params.id;
            if(id) {
                await store.dispatch('categories/get', {id});
                await store.dispatch('categories/fetch', {});
                this.entity = this.oldEntity;
            }
        },

        methods: {
            async setMainPicture(file) {
                this.entity.picture = await store.dispatch('files/upload', { file });
            },
            async save() {
                try {
                    store.commit('categories/SET_ENTITY', this.entity);
                    await store.dispatch('categories/save');
                    this.goBack();
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('categories/delete', {id});
                    this.$router.push('/categories');
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/categories/${id}/detail`);
                } else {
                    this.$router.push('/categories');
                }
            },
        },
    };
</script>
